import { HttpClient } from '@angular/common/http';
import { Injectable, inject, signal } from '@angular/core';
import { Observable, ObservableInput, catchError, map, of, tap } from 'rxjs';

import { environment } from '@env';

export interface UnavailabilityNotice {
  isActive: boolean;
  message: string;
}

@Injectable({
  providedIn: 'root',
})
export class UnavailabilityNoticeService {
  readonly unavailabilityNoticeState = signal<UnavailabilityNotice>({ isActive: false, message: '' });

  private readonly http = inject(HttpClient);

  update(languageCode: string): void {
    this.get(languageCode).subscribe((value) => this.unavailabilityNoticeState.set(value));
  }

  get(languageCode: string): Observable<UnavailabilityNotice> {
    if (environment.isProduction) {
      const url = `/api/unavailability-notice?langCode=${languageCode}`;

      return this.http.get<UnavailabilityNotice>(url).pipe(
        map((response: UnavailabilityNotice) => {
          return {
            isActive: response.isActive,
            message: response.message,
          };
        }),
        catchError(
          (_error: Error, _caught: Observable<UnavailabilityNotice>): ObservableInput<UnavailabilityNotice> => {
            return of({
              isActive: false,
              message: '',
            });
          },
        ),
        tap((value) => this.unavailabilityNoticeState.set(value)),
      );
    } else {
      return of({
        isActive: false,
        message: '',
      });
    }
  }
}
